import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { getSensors, reset, filterSensors, editSensor, getStatus } from '../features/sensor/sensorSlice';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';
import AlertDialog from './AlertDialog';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CreateView from './CreateView';
import CreateKey from './CreateKey';
import KeyIcon from '@mui/icons-material/Key';
import CircularProgress from '@mui/material/CircularProgress';

function createData(id, mac, name, key, owner, mqtt) {
    return {
        id,
        mac,
        name,
        key,
        owner,
        mqtt,
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'mac',
        numeric: true,
        disablePadding: false,
        label: 'MAC',
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'key',
        numeric: true,
        disablePadding: false,
        label: 'Key',
    },
    {
        id: 'owner',
        numeric: true,
        disablePadding: false,
        label: 'Owner',
    },
    {
        id: 'mqtt',
        numeric: true,
        disablePadding: false,
        label: 'MQTT Status',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, changeSearchTerm, handleClickOpen, searchTerm, handleUpdate, handleClickCreateView, user, handleClickCreateKey } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Sensors
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={handleClickOpen}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="">
                    <>
                        {user.user.permissionLevel === "admin" ?
                            <IconButton onClick={handleClickCreateKey} style={{ marginRight: "30px" }}>
                                <KeyIcon />
                            </IconButton>
                            : null}
                        <IconButton onClick={handleUpdate} style={{ marginRight: "30px" }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleClickCreateView} style={{ marginRight: "30px" }}>
                            <AddBoxIcon />
                        </IconButton>
                        <input value={searchTerm} onChange={changeSearchTerm} style={{ marginRight: "30px", height: "20px", width: "300px" }}></input>
                    </>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function HasOwner() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('mac');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { user } = useSelector((state) => state.auth)
    const { status, sensors, isError, message, isLoading, isAction } = useSelector((state) => state.sensors);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [dataUpdate, setDataUpdate] = React.useState([]);
    const [openCreateView, setOpenCreateView] = React.useState(false);
    const [openCreateKey, setOpenCreateKey] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickCreateView = () => {
        setOpenCreateView(true);
    };

    const handleCloseCreateView = () => {
        setOpenCreateView(false);
    };

    const handleClickCreateKey = () => {
        setOpenCreateKey(true);
    };

    const handleCloseCreateKey = () => {
        setOpenCreateKey(false);
    };

    const changeSearchTerm = (e) => {
        setSearchTerm(e.target.value)
    };

    React.useEffect(() => {
        setPage(0)
        dispatch(filterSensors(searchTerm));
    }, [searchTerm, dispatch]);

    const rows = sensors?.map((i) =>
        createData(
            i.id,
            i.mac,
            i.name,
            i.key?.key,
            i.key?.owner?.name
        )
    );

    React.useEffect(() => {
        let repeat;

        async function fetchData() {
            try {
                if (isError) {
                    toast.error(isError)
                }

                if (!user) {
                    navigate('/')
                }
                dispatch(getStatus())

                repeat = setTimeout(fetchData, 5000); // request again after a minute
            } catch (error) {
                console.error(error.message)
            }
        }

        fetchData();

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
            dispatch(reset())
        }
    }, [user, navigate, isError, message, dispatch])

    React.useEffect(() => {
        if (isError) {
            toast.error(isError)
        }

        if (user == null) {
            navigate('/')
        }

        dispatch(getSensors())
        return () => {
            dispatch(reset())
        }
    }, [user, navigate, isError, message, dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleTextFieldChange = (e, id, mac) => {
        const data = "";
        const { name, value } = e.target;
        const result = {
            ...data,
            id: id,
            mac: mac.trim(),
            [name]: value.trim(),
        }
        let keyIndex = Object.keys(result)
        let isBool = true

        for (let i = 0; dataUpdate.length >= i; i++) {
            if (dataUpdate[i]?.hasOwnProperty(keyIndex[1]) && (dataUpdate[i].id == result.id)) {
                dataUpdate[i] = result;
                isBool = false
            }
        }

        if (isBool) {
            dataUpdate.push(result);
        }

        setDataUpdate(dataUpdate);
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (dataUpdate.length > 0) {
            let actionResult = await dispatch(editSensor(dataUpdate))
            setDataUpdate([])
            if (actionResult.error?.message === "Rejected" && actionResult.error?.message !== undefined) {
                toast.error("There is something wrong")
            }
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            {isLoading ?
                <CircularProgress /> :
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar handleClickCreateKey={handleClickCreateKey} user={user} handleClickCreateView={handleClickCreateView} handleUpdate={handleUpdate} searchTerm={searchTerm} handleClickOpen={handleClickOpen} changeSearchTerm={changeSearchTerm} numSelected={selected.length} />
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: "0px" }}>
                    </Box>
                    <AlertDialog setSearchTerm={setSearchTerm} setSelected={setSelected} selected={selected} handleClose={handleClose} open={open} />
                    <CreateView openCreateView={openCreateView} handleCloseCreateView={handleCloseCreateView} />
                    <CreateKey openCreateKey={openCreateKey} handleCloseCreateKey={handleCloseCreateKey} />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.mac}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="right">{row.mac.toLowerCase()}</TableCell>
                                                <TableCell align="right"><input name="name" onChange={(e) => handleTextFieldChange(e, row.id)} defaultValue={row.name} style={{ "textAlign": "right", "width": "200px", "border": "hidden", "fontFamily": "Roboto, Helvetica,Arial ,sans-serif", "fontSize": "0.875rem" }} /></TableCell>
                                                <TableCell align="right"><input name="key" onChange={(e) => handleTextFieldChange(e, row.id, row.mac)} defaultValue={row.key} style={{ "textAlign": "right", "width": "280px", "border": "hidden", "fontFamily": "Roboto, Helvetica,Arial ,sans-serif", "fontSize": "0.875rem" }} /></TableCell>
                                                <TableCell align="right">{row.owner}</TableCell>
                                                <TableCell align="right">{status.map(i => i.mac === row.mac && isAction ? i.status === "online" ? <div key={i.mac}><FiberManualRecordIcon color="success" /> </div> : <div key={i.mac}><FiberManualRecordIcon color="error" /></div> : null)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={10} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            }
        </Box>
    );
}