import Login from './components/Login'
import Containers from './components/Containers'
import NoMatch from './components/NoMatch'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'

function App() {
  return (
    <>
      <Router>
        <div className='container'>
          <Routes>
            <Route>
              <Route path='/' element={<Login />} />
              <Route path='/mainpage' element={<ProtectedRoute><Containers /></ProtectedRoute>} />
              <Route path='*' element={<NoMatch />} />
            </Route>
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
