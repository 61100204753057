import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import sensorService from './sensorService'

const initialState = {
    sensors: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    filteredSensors: [],
    key: null,
    sensorsGraph: [],
    filteredGraph: [],
    status: [],
    isAction: false,
}

//Create new sensors
export const createSensors = createAsyncThunk(
    'sensor/create',
    async (sensorData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.createSensor(sensorData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Create new sensors with changing owner
export const createNewSensors = createAsyncThunk(
    'sensor/createOnwer',
    async (sensorData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.createOwnerSensor(sensorData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get sensors
export const getSensors = createAsyncThunk(
    'sensor/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.getSensors(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get sensors
export const getStatus = createAsyncThunk(
    'sensor/getStatus',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.getSensors(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get sensors now owners
export const getNoOwners = createAsyncThunk(
    'sensor/getNoOwners',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.getNoOwners(token);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete sensors
export const deleteSensor = createAsyncThunk(
    'sensor/delete',
    async (arrIds, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.deleteSensor(arrIds, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)


// Edit sensors
export const editSensor = createAsyncThunk(
    'sensor/edit',
    async (sensorData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.editSensor(sensorData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Create key
export const createKey = createAsyncThunk(
    'sensor/key',
    async (keyData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user?.refreshToken;
            return await sensorService.createKey(keyData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const sensorSlice = createSlice({
    name: 'sensor',
    initialState,
    reducers: {
        reset: (state) => initialState,
        filterSensors: (state, action) => {
            state.sensors = state.filteredSensors.filter((sensor) => sensor.id.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.mac.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.name.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.key.key.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.key.owner.name.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.status?.toLowerCase().includes(action.payload.toLowerCase().trim())
            );
        },
        deleteKey: (state, action) => { state.key = null },
        filterNoOwner: (state, action) => {
            state.sensorsGraph = state.filteredGraph.filter((sensor) => sensor.uuid.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.mac.toLowerCase().includes(action.payload.toLowerCase().trim()) ||
                sensor.name.toLowerCase().includes(action.payload.toLowerCase().trim())
            );
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSensors.pending, (state) => {
                //state.isLoading = true
            })
            .addCase(createSensors.fulfilled, (state, action) => {
                //state.isLoading = false
                state.isSuccess = true
                state.sensors.push(action.payload[0])
                state.filteredSensors.push(action.payload[0])
            })
            .addCase(createSensors.rejected, (state, action) => {
                //state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(createNewSensors.pending, (state) => {
                //state.isLoading = true
            })
            .addCase(createNewSensors.fulfilled, (state, action) => {
                //state.isLoading = false
                state.isSuccess = true
                state.sensorsGraph = action.payload
                state.filteredGraph = action.payload
            })
            .addCase(createNewSensors.rejected, (state, action) => {
                //state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(createKey.pending, (state) => {
                //state.isLoading = true
            })
            .addCase(createKey.fulfilled, (state, action) => {
                //state.isLoading = false
                state.isSuccess = true
                const key = action.payload
                state.key = key.key
            })
            .addCase(createKey.rejected, (state, action) => {
                //state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getSensors.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSensors.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.sensors = action.payload
                state.filteredSensors = action.payload
            })
            .addCase(getSensors.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getStatus.pending, (state) => {
                state.isAction = true
            })
            .addCase(getStatus.fulfilled, (state, action) => {
                state.isAction = true
                state.status = action.payload
                state.filteredSensors = action.payload
            })
            .addCase(getStatus.rejected, (state, action) => {
                state.isAction = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getNoOwners.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNoOwners.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.sensorsGraph = action.payload
                state.filteredGraph = action.payload
            })
            .addCase(getNoOwners.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteSensor.pending, (state) => {
                //state.isLoading = true
            })
            .addCase(deleteSensor.fulfilled, (state, action) => {
                //state.isLoading = false
                state.isSuccess = true
                const arrIds = action.payload;
                state.sensors = state.sensors.filter(
                    (sensor) => !Array.from(arrIds).includes(sensor.id)
                )
                state.filteredSensors = state.filteredSensors.filter(
                    (sensor) => !Array.from(arrIds).includes(sensor.id)
                )
            })
            .addCase(deleteSensor.rejected, (state, action) => {
                //state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(editSensor.pending, (state) => {
                //state.isLoading = true
            })
            .addCase(editSensor.fulfilled, (state, action) => {
                //state.isLoading = false
                state.isSuccess = true
                state.sensors = action.payload
                state.filteredSensors = action.payload
            })
            .addCase(editSensor.rejected, (state, action) => {
                //state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset, filterSensors, deleteKey, filterNoOwner } = sensorSlice.actions
export default sensorSlice.reducer