import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import LogoutIcon from '@mui/icons-material/Logout';
import MainPage from './Mainpage'

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate(-1);
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        </Typography>
                        <Button color="inherit" onClick={onLogout}><LogoutIcon />Logout</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <MainPage />
        </>
    );
}