import * as React from 'react';
import { Button, TextField, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { createSensors, reset } from '../features/sensor/sensorSlice';
import { toast } from 'react-toastify';

export default function CreateView(props) {
    const { openCreateView, handleCloseCreateView } = props;
    const dispatch = useDispatch()

    const [formValue, setFormValue] = React.useState({
        mac: "",
        name: "",
        details: "",
        key: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handlecreateSensors = async (e) => {
        e.preventDefault();
        if (formValue.mac == "" || formValue.name == "" || formValue.key == "") {
            toast.error("Required Fields Missing")
        } else {
            formValue.mac = formValue.mac.trim();
            formValue.key = formValue.key.trim();
            const actionResult = await dispatch(createSensors(formValue))
            setFormValue({
                mac: "",
                name: "",
                details: "",
                key: ""
            })
            if (actionResult.error?.message === "Rejected" && actionResult.error?.message !== undefined) {
                toast.error("Error with MAC or Key")
            }
        }
    }

    const handleClose = () => {
        handleCloseCreateView()
        setFormValue({
            mac: "",
            name: "",
            details: "",
            key: ""
        })
    }

    const { mac, name, details, key } = formValue;

    return (
        <div>
            <Dialog
                open={openCreateView}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", color: "blue" }}>
                    {"ADD NEW SENSORS"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                        MacAddress
                    </DialogContentText>
                    <TextField
                        id="mac"
                        variant="standard"
                        name="mac"
                        label="Required"
                        value={mac}
                        required
                        onChange={handleChange}
                        style={{ paddingBottom: "20px", width: "400px" }}
                    />
                    <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                        Name
                    </DialogContentText>
                    <TextField
                        id="name"
                        variant="standard"
                        name="name"
                        label="Required"
                        value={name}
                        required
                        onChange={handleChange}
                        style={{ paddingBottom: "20px", width: "400px" }}
                    />
                    <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                        Details
                    </DialogContentText>
                    <TextField
                        id="details"
                        variant="standard"
                        name="details"
                        value={details}
                        onChange={handleChange}
                        style={{ paddingBottom: "20px", width: "400px" }}
                    />
                    <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                        Key
                    </DialogContentText>
                    <TextField
                        id="key"
                        name="key"
                        variant="standard"
                        value={key}
                        label="Required"
                        onChange={handleChange}
                        required
                        style={{ paddingBottom: "20px", width: "400px" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="success" onClick={handlecreateSensors}> Create </Button>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}