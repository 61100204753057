import axios from 'axios'
import cfg from '../../constants'

// Create new sensors
const createSensor = async (sensorData, token) => {
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token })
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }
    const response = await axios.post(cfg.API_URL, sensorData, config)
    const getData = await axios.get(cfg.API_URL, config)
    const data = getData.data.filter(data => data.id === response.data.id)
    return data
}

// Create new sensors with changing owner
const createOwnerSensor = async (sensorData, token) => {
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token })
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }
    await axios.post(cfg.API_URL, sensorData, config)
    let data = getNoOwners(token)
    return data
}


const createKey = async (keyData, token) => {
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token })
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }
    const response = await axios.post(cfg.API_URL_KEY, keyData, config)
    return response.data
}

// Get no owners
const getNoOwners = async (tokenAPI) => {
    let finalResult = []
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token: tokenAPI })
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }
    const response = await axios.get(cfg.API_URL, config)
    return response.data
}

function getDifference(array1, array2) {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return object1.mac.toLowerCase() === object2.mac.toLowerCase();
        });
    });
}

// Get sensors
const getSensors = async (token) => {
    let finalResult = []
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token })
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }

    const response = await axios.get(cfg.API_URL, config)
    return response.data
}

// Delete sensors
const deleteSensor = async (arrIds, token) => {
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token })
    let arrResponse = []
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }
    for (let i = 0; i < arrIds.length; i++) {
        const response = await axios.delete(cfg.API_URL + `/${arrIds[i]}`, config)
        const result = response.data.id
        arrResponse.push(result);
    }
    return arrResponse
}

// Edit sensors
const editSensor = async (sensorData, token) => {
    const refreshedToken = await axios.post(cfg.API_URL_TOKEN, { token })
    const config = {
        headers: {
            Authorization: `Bearer ${refreshedToken.data.token}`,
        },
    }
    for (let key in sensorData) {
        let keyId = "";
        let data = {};
        const result = sensorData[key]
        for (const key in result) {
            if (key == 'id') {
                keyId = result[key];
            } else {
                data = { [key]: result[key] }
            }
        }
        await axios.patch(cfg.API_URL + `/${keyId}`, data, config)
    }
    const response = await axios.get(cfg.API_URL, config)
    console.log(sensorData)
    return response.data
}

const sensorService = {
    createSensor,
    getSensors,
    deleteSensor,
    editSensor,
    createKey,
    getNoOwners,
    createOwnerSensor,
}

export default sensorService