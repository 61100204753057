import {
    Navigate,
    useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux'


const useAuth = () => {
    const { user } = useSelector((state) => state.auth)
    return user;
}

const ProtectedRoute = ({ children }) => {
    const isAuth = useAuth();
    const location = useLocation();
    return isAuth ? children : <Navigate to="/" replace state={{ from: location }} />;
};

export default ProtectedRoute;