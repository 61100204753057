import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../features/auth/authSlice';
import sensorReducer from '../features/sensor/sensorSlice';


const reducer = combineReducers({
    auth: authReducer,
    sensors: sensorReducer
});

export default configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
});