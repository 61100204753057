import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { deleteSensor } from '../features/sensor/sensorSlice';

export default function AlertDialog(props) {
    const { open, handleClose, selected, setSelected } = props;
    const dispatch = useDispatch()

    const deleteSensors = (e) => {
        e.preventDefault();
        dispatch(deleteSensor(selected))
        setSelected([])
        handleClose()
    }

    const cancelDetele = () => {
        setSelected([])
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Comfirm Delete Sensor"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cancelDetele}>Close</Button>
                    <Button variant="outlined" color="error" onClick={deleteSensors} autoFocus> Yes </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}