import axios from 'axios'

const API_URL = 'https://api.server.nome.fi/v1/login'

// Login user
const login = async (userData) => {
    const response = await axios.post(API_URL, userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Logout user
const logout = () => {
    localStorage.removeItem('user')
}

const authService = {
    logout,
    login,
}

export default authService