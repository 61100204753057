import * as React from 'react';
import { Button, TextField, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { createKey, deleteKey, getNoOwners } from '../features/sensor/sensorSlice';
import { toast } from 'react-toastify';

export default function CreateKey(props) {
    const { openCreateKey, handleCloseCreateKey } = props;
    const dispatch = useDispatch()
    const { key } = useSelector((state) => state.sensors)

    const [formValue, setFormValue] = React.useState({
        name: "",
        owner: "",
        expiresAt: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handlecreateSensors = async (e) => {
        e.preventDefault();
        dispatch(getNoOwners())
        if (formValue.name == "" || formValue.owner == "" || formValue.expiresAt == "") {
            toast.error("Required Fields Missing")
        } else {
            formValue.expiresAt = formValue.expiresAt.trim();
            const actionResult = await dispatch(createKey(formValue))
            setFormValue({
                name: "",
                owner: "",
                expiresAt: ""
            })
            if (actionResult.error?.message === "Rejected" && actionResult.error?.message !== undefined) {
                toast.error("Please try again")
            }
        }
    }

    const handleClose = () => {
        setFormValue({
            name: "",
            owner: "",
            expiresAt: ""
        })
        dispatch(deleteKey())
        handleCloseCreateKey()
    }

    const { owner, name, expiresAt } = formValue;

    return (
        <div>
            <Dialog
                open={openCreateKey}
                onClose={handleCloseCreateKey}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {key !== null ?
                    <div>
                        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", color: "blue" }}>
                            {"NEW KEY"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                                HERE IS YOUR KEY : {key}
                                <Button onClick={() => navigator.clipboard.writeText(key)} style={{ marginLeft: "20px" }}>
                                    Copy
                                </Button>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </div> :
                    <div>
                        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", color: "blue" }}>
                            {"CREATE KEY"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                                Name
                            </DialogContentText>
                            <TextField
                                id="name"
                                variant="standard"
                                name="name"
                                label="Required"
                                value={name}
                                required
                                onChange={handleChange}
                                style={{ paddingBottom: "20px", width: "400px" }}
                            />
                            <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                                Owner
                            </DialogContentText>
                            <TextField
                                id="owner"
                                variant="standard"
                                name="owner"
                                value={owner}
                                onChange={handleChange}
                                label="Required"
                                required
                                style={{ paddingBottom: "20px", width: "400px" }}
                            />
                            <DialogContentText style={{ color: "black" }} id="alert-dialog-description">
                                Expire Day
                            </DialogContentText>
                            <DialogContentText style={{ color: "red", fontSize: "13px" }} id="alert-dialog-description">
                                Example: 2022-01-01T00:00:00.000Z
                            </DialogContentText>
                            <TextField
                                id="expiresAt"
                                name="expiresAt"
                                variant="standard"
                                value={expiresAt}
                                label="Required"
                                onChange={handleChange}
                                required
                                style={{ paddingBottom: "20px", width: "400px" }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="success" onClick={handlecreateSensors}> Create </Button>
                            <Button variant="outlined" onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </div>
                }
            </Dialog>
        </div>
    );
}